/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import {
  Button, Card, Container, Grid, Typography,
} from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout, { LayoutContext } from '../../components/Layout';

function NewsTemplate(props) {
  const { mobileMode } = React.useContext(LayoutContext);
  const { edges: posts } = props.data.allMarkdownRemark;

  return (
    <Container maxWidth="lg">
      <Grid container direction="column" alignItems="center" rowGap={6} sx={{ py: 4 }}>
        <Typography
          variant="h1"
          sx={
            mobileMode
              ? {
                fontFamily: 'Poppins',
                fontSize: '60px',
                fontWeight: '500',
                lineHeight: '80px',
                letterSpacing: '0em',
                textAlign: 'center',
              }
              : {
                fontFamily: 'Poppins',
                fontSize: '68px',
                fontWeight: '500',
                lineHeight: '102px',
                letterSpacing: '0em',
                textAlign: 'left',
              }
          }
        >
          All News Posts
        </Typography>
        <Grid container columnGap={2} rowGap={2}>
          {posts
            && posts.map(({ node: post }) => {
              const fullWidthImage = getImage(post.frontmatter.featuredimage) || post.frontmatter.featuredimage;

              return (
                <Grid item xs="12" sm="5.7" lg="3.8">
                  <Card elevation={1}>
                    <Grid container column alignItems="flex-start" rowGap={3}>
                      <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                        <Link to={post.frontmatter.link} target="_blank">
                          <GatsbyImage
                            image={fullWidthImage}
                            alt={`Squaddie Golf News Image for ${post.frontmatter.title}`}
                          />
                        </Link>
                      </div>

                      <Grid
                        container
                        direction="column"
                        rowGap={3}
                        sx={{ height: '380px', py: 2, px: 3 }}
                        justifyContent="space-between"
                      >
                        <Grid container rowGap={3} direction="column">
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: 'Poppins',
                              fontSize: '14px',
                              fontWeight: '500',
                              lineHeight: '21px',
                              letterSpacing: '0px',
                              textAlign: 'left',
                              color: '#6F707A',
                            }}
                          >
                            {post.frontmatter.date}
                          </Typography>
                          <Link to={post.frontmatter.link} target="_blank">
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: 'Space Grotesk',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '31px',
                                letterSpacing: '0px',
                                textAlign: 'left',
                                color: 'black',
                              }}
                            >
                              {post.frontmatter.title}
                            </Typography>
                          </Link>
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: 'Poppins',
                              fontSize: '16px',
                              fontWeight: '400',
                              lineHeight: '24px',
                              letterSpacing: '0px',
                              textAlign: 'left',
                              color: '#6F707A',
                            }}
                          >
                            {post.frontmatter.source}
                          </Typography>
                        </Grid>
                        <Link to={post.frontmatter.link} target="_blank">
                          <Button
                            variant="outlined"
                            sx={{
                              color: '#5EBC67',
                              borderColor: '#5EBC67',
                              height: '70px',
                              fontFamily: 'Open Sans',
                              fontSize: '16px',
                              fontWeight: '700',
                              letterSpacing: '0px',
                              textAlign: 'center',
                              textTransform: 'none',
                              maxWidth: '172px',
                            }}
                          >
                            Read More →
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Container>
  );
}

News.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function News() {
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query NewsQuery {
            allMarkdownRemark(
              limit: 500
              sort: { order: DESC, fields: [frontmatter___date] }
              filter: { frontmatter: { templateKey: { eq: "news-link" } } }
            ) {
              edges {
                node {
                  excerpt(pruneLength: 120)
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    source
                    link
                    templateKey
                    date(formatString: "MMMM DD, YYYY")
                    featuredimage {
                      childImageSharp {
                        gatsbyImageData(width: 600, height: 400, quality: 100, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data, count) => <NewsTemplate data={data} count={count} />}
      />
    </Layout>
  );
}
